body {
  font-size: 16px;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */

  font-family: "Lato", sans-serif, Georgia, Times, "Times New Roman", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h5 {
  letter-spacing: 1px;
}
body {
  min-height: 100%;
}

div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

html {
  overflow-x: hidden;
  font-size: 100%;
  scroll-behavior: smooth;
  overflow-y: scroll;
}
head {
  display: none;
}
body {
  background-color: white;
  animation: intro 1.5s;
}

* {
  box-sizing: border-box;
  user-select: none;
  margin: 0;
  padding: 0;
  outline: none;
}
*:focus {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0.2;
  font-variant: small-caps;
}
h1,
h2,
h4 {
  width: 100%;
  max-width: 100%;
  text-align: center;

  color: #400042;
}
h1 {
  text-align: left;
}

.active {
  color: yellow;
}
.arrows {
  opacity: 1;
  cursor: pointer;
  transition: 0.5s;
}
.arrows:hover {
  filter: brightness(150%);
  transition: 0.5s;
}

@keyframes intro {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.someicon {
  margin: 1em 1em 1em 0;
}

@keyframes marsu {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(+100%);
  }
}

.video:hover {
  transform: scale(1.05);
  transition: 1s;
}
.video {
  transition: 1s;
  overflow: hidden;
}

.gallery_image {
  cursor: pointer;
  transition: 1s;
  overflow: hidden;
  opacity: 1;
}

.gallery_image:hover {
  transform: scale(1.03);
  transition: 0.8s;
}

.imageiconHide {
  opacity: 0;
}
.imageicon {
  opacity: 1;
  cursor: pointer;
}
.imageicon:hover {
  transition: 0.2s;
  opacity: 1;
}
.smalltext {
  font-size: 0.85em;

  padding: 0.1em 0 0.1em 0;
}
.fader {
  height: 20%;
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(240, 240, 240, 0.61),
    rgb(255, 255, 255)
  );
}

.play {
  position: absolute;
  margin: 0 auto;
  z-index: 50;
  cursor: pointer;
  border-radius: 999px;
  opacity: 0.7;
}
.play:hover {
  opacity: 1;

  transition: 0s;
}

.gallery_arrows {
  cursor: pointer;
  position: absolute;
  opacity: 0.5;
}
.gallery_arrows:hover {
  opacity: 1;
}

.lehtijutut a {
  text-decoration: none;
  height: max-content;
  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  min-height: 1px;
  height: 1px;
  max-height: 1px;
  background-color: #400042;
}
