.admin_app {
  background: white;
  height: auto;
  width: 100vw;
}

.admin_banner {
  max-height: 100vh;
  max-width: 100%;
}

.admin_app_coursevideos {
  background: white;
  height: auto;
  max-width: 100vw;
}

.admin_app.courses {
  width: 100vw;
}

.admin_form {
  background-color: white;
  margin: 0 auto;
  padding: 0;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 5px rgb(158, 158, 158) solid;
  padding: 3rem;
}

.admin_list {
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
